import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/templates/MDXDocs.js";
import { Divider } from 'fannypack';
import ColouredText from '../../../components/worksafe-vic/ColouredText';
import Highlight from '../../../components/worksafe-vic/Highlight';
import NumberLabel from '../../../components/worksafe-vic/NumberLabel';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "response-payload",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#response-payload",
        "aria-label": "response payload permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Response payload`}</h1>
    <p>{`The response will echo the request with the following important response fields:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Field`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Format`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Example value`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Description`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`_id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Up to 128 characters`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{` 5ce4d63cb0a38c005582b838 `}</Highlight></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Medipass unique transaction reference - use this to query invoice status`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`transactionId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`24 characters`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`0000-1234-5678`}</Highlight></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A user friendly transaction reference for phone support with Tyro Health.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`businessId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Up to 128 Characters`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`5c3fb7256af30100c321a7eb `}</Highlight></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Business ID is unique to a practice and the identifier may be required for manually requesting remittance data, payment reports and processing reports which apply across a business rather than a specific claim or provider.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`patient `}</inlineCode>{`{}`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Object`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Patient names as validated with funder. Note that updated name details may be returned.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`patient.firstName`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Up to 40 characters`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`Mila `}</Highlight></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Patient first / given name as registered with funder. Note that if the member name differs, then the fund may return an updated first name. E.g., patient submitted as `}{`"`}{`Adrian`}{`"`}{` may return as `}{`"`}{`Adriano`}{`"`}{`. The name returned should be validated by the provider to match patient records.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`patient.lastName`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Up to 40 characters`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{` Willis`}</Highlight></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Patient last / family name as registered with funder. Note that if the member name differs, then the fund may return an updated first name. E.g., patient last name submitted as `}{`"`}{`Perillo`}{`"`}{` may return as `}{`"`}{`Perillo-Phipps`}{`"`}{`. The name returned should be validated by the provider to match patient records.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`claims.status`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Up tp 256 characters`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{` Approved `}</Highlight></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Description of overall claim`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`claims.gatewayDescription`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Up to 56 characters`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`Approved `}</Highlight></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gateway response`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`claims.gatewayCode`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4 alphanumeric`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{` 615 `}</Highlight></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`See `}<strong parentName="td">{`Medicare Online return codes`}</strong>{` `}<a parentName="td" {...{
              "href": "https://www.servicesaustralia.gov.au/medicare-digital-claiming-return-codes"
            }}><strong parentName="a">{`https://www.servicesaustralia.gov.au/medicare-digital-claiming-return-codes`}</strong></a>{`for list of possible response codes. Codes apply for Medicare and DVA claims.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`claims.amountActualBenefit /claims.amountActualBenefitString`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`8000 / $80.00`}</Highlight></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Total claim benefit amount in cents or (string) in currency notation.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`claims.amountGap/ claims.amountGapString`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{` 6000 / $60.00 `}</Highlight></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Total claim gap (member payable) amount in cents or (string) in currency notation.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`claims.\\_Id `}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Up to 128 characters`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{` 6261f2be51619a008576a0e7 `}</Highlight></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Claim id - use this to query the printed statement or payment/processing report.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`claims.claimItems`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Array`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`For each claimed item, a separate line level response as below.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`claims.claimItems.status`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Up to 256 characters`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{` Approved`}</Highlight></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Description of the claim/quote item level status`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`claims.claimItems.gatewayDescription`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Up to 56 characters`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{` Approved `}</Highlight></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gateway response for item`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`claims.claimItems.gatewayCode`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4 alphanumeric`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{` 9699`}</Highlight></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`See `}<strong parentName="td">{`Medicare Online return codes`}</strong>{` `}<a parentName="td" {...{
              "href": "https://www.servicesaustralia.gov.au/medicare-digital-claiming-return-codes"
            }}><strong parentName="a">{`https://www.servicesaustralia.gov.au/medicare-digital-claiming-return-codes`}</strong></a>{`for list of possible response codes. Codes apply for Medicare and DVA claims.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`claims.claimItems.amountExpectedBenefit`}</inlineCode>{` / String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Currency`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{` 3875 / $38.75 `}</Highlight></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Standard MBS rebate amount`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`claims.claimItems.amountActualBenefit`}</inlineCode>{` / String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Currency`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`4000 / $40.00`}</Highlight></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Claim item benefit amount in cents or (string) in currency notation.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`externalReferences{}`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Key-pair object`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`"`}{`displayName`}{`"`}{`: `}{`"`}{`Medicare Transaction ID`}{`"`}{`,`}{`"`}{`key`}{`"`}{`: `}{`"`}{`medicareTransactionId`}{`"`}{`,`}{`"`}{`value`}{`"`}{`: `}{`"`}{`PAO00000xNFIkFtnt0kbwln2`}{`"`}{`  `}</Highlight></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Services Australia transaction IDs - for provider support requests to Medicare/DVA.`}</td>
        </tr>
      </tbody>
    </table>
    <h1 {...{
      "id": "rejected-claims",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#rejected-claims",
        "aria-label": "rejected claims permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Rejected claims`}</h1>
    <p>{`For rejected claims, we suggest to present both the overall claim level and item level responses as the detail or rejection can be exposed at either level:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`claims[].gatewayDescription`}</inlineCode>{` :  for overall claim level responses`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`claims[].claimItems[].gatewayDescription`}</inlineCode>{` : for item level details `}<br /><br />{`
We also include the Medicare/DVA three or four digit response codes for provider reference:`}<br /><br /></li>
      <li parentName="ul"><inlineCode parentName="li">{`claims[].gatewayCode`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`claims[].claimItems[].gatewayCode`}</inlineCode><br /><br />{`
And can we expanded these two attributes to say:`}<br /><br /></li>
      <li parentName="ul"><inlineCode parentName="li">{`claims[].gatewayDescription`}</inlineCode>{` : Gateway description for overall claim. This will include details for claim level rejections or declines.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`claims[].claimItems[].gatewayDescription`}</inlineCode>{`: Gateway description for each claim item. This will include details for item level rejections or declines.`}</li>
    </ul>
    <h2 {...{
      "id": "invoice-status-api",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#invoice-status-api",
        "aria-label": "invoice status api permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Invoice status API`}</h2>
    <p>{`Partners may call the invoice status endpoint to obtain the current status. The returned data will reflect the current invoice and claimItem status. Use the following method and API endpoint:`}</p>
    <p><strong parentName="p">{`GET {baseurl}/v3/transactions/{transactionId}`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      